<template>
  <div class="main">
    <div class="login">
      <p>象牙智能识别系统</p>
      <a-input-password prefix="密码&nbsp;&nbsp;&nbsp;|"
                        placeholder="请输入密码"
                        v-model:value='pwd' @keydown.enter="login"/>
      <div class="button" @click="login">登录</div>
    </div>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import Cookies from 'js-cookie'

export default {
  name: 'Login',
  mixins: [],
  data() {
    return {
      // pwd: '2021@ivory'
      pwd: ''
    }
  },
  methods: {
    login() {
      /* TODO 此处添加登录验证接口 */
      if (this.pwd.replace(/(^s*)|(s*$)/g, '').length === 0) {
        message.info('请输入正确的密码')
        // eslint-disable-next-line no-empty
      } else {
        Cookies.set('token', 'emulian')
        this.$router.replace('/PcHome')
      }
    }
  },
  components: {
    message
  },
  created() {}
}
</script>

<style lang="less" scoped>
  .main {
    background-image: url("../../assets/pc/login-bg.jpg");
    display: table;
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    position: fixed;
    width: 100%;
    .login {
      background-color: rgba(255,255,255,0.8);
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -140px 0 0 -250px;
      width: 500px;
      height: 280px;
      p{
        width: 100%;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        color: #333333;
        margin-top: 58px;
        margin-bottom: 40px;
      }
      /deep/ .ant-input-affix-wrapper{
        width: 302px;
        height: 42px;
        input{
          padding-left: 8px;
        }
      }
      .button {
        width: 302px;
        height: 40px;
        opacity: 1;
        background: #ddbf83;
        border-radius: 4px;
        font-size: 16px;
        line-height: 40px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
</style>
