<template>
  <div class="main">
    <div class="login">
      <div class="head">
        <img src="../../assets/mobile/BG.png" />
      </div>
      <p>象牙智能识别系统</p>
      <a-input-password prefix="密码&nbsp;&nbsp;&nbsp;|"
                        placeholder="请输入密码"
                        v-model:value='pwd'/>
      <div class="button" @click="login">登录</div>
    </div>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import Cookies from 'js-cookie'

export default {
  name: 'Login',
  mixins: [],
  data() {
    return {
      // pwd: '2021@ivory'
      pwd: ''
    }
  },
  methods: {
    login() {
      /* TODO 此处添加登录验证接口 */
      if (this.pwd.replace(/(^s*)|(s*$)/g, '').length === 0) {
        message.info('请输入正确的密码')
        // eslint-disable-next-line no-empty
      } else {
        Cookies.set('token', 'emulian')
        this.$router.replace('/MobileHome')
      }
    }
  },
  components: {
    message
  },
  created() {}
}
</script>

<style lang="less" scoped>
  .main {
    .login {
      .head{
        width: 100%;
        // background-image: url("../../assets/pc/login-bg.png");
        // background-repeat:no-repeat;
        // background-size: 100% auto;
        img {
          width: 100%;
        }
      }
      p{
        width: 100%;
        font-size: 4.2vw;
        font-weight: 600;
        text-align: center;
        color: #333333;
        margin-top: 16vw;
        margin-bottom: 8vw;
      }
      /deep/ .ant-input-affix-wrapper{
        width: 80.53vw;
        height: 11.2vw;
        font-size: 4.26vw;
        padding: 0 4vw;
        input{
          padding-left: 2.13vw;
          font-size: 4.26vw;
        }
      }
      .button {
        width: 80.53vw;
        height: 11.2vw;
        font-size: 4.26vw;
        opacity: 1;
        background: #ddbf83;
        border-radius: 1.06vw;
        margin: 8vw auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
</style>
