<template>
  <div class="main">
    <div class="context">
      <div class="text">
        <div class="title">象牙样本</div>
        <div class="content">大象是地球上珍贵的野生动物资源，非洲象，亚洲象均被替换为《濒临灭绝的野生动植物种国际贸易公约》（CITES ）目录，象牙及其制品的市场贸易持续得到国家决策层和学术界的从抵抗制象牙及制品做起</div>
      </div>
      <div class="banner">
        <img src="../../assets/mobile/banner.png" />
      </div>
    </div>
    <div class="sample" id="sample" v-if="examples.length > 0">
      <p class="title">象牙样本</p>
      <hr/>
      <div class="menu">
        <div v-for="item in examples"
              :key="item.meta.index"
              :class="item.index == active? 'active' : ''"
              @click="changeClass(item.index)">
          {{item.meta[0].value}}
        </div>
      </div>
      <div class="display">
        <ImgSwiper :menuIfo="examples"
          :active="active"
          @slideIndex="getSlideIndex"
          ref="classImgs"></ImgSwiper>
      </div>
    </div>
    <div class="description" id="description">
      <p class="title">项目介绍</p>
      <hr />
      <div class="content">大象是地球上珍贵的野生动物资源，非洲象，亚洲象均被替换为《濒临灭绝的野生动植物种国际贸易公约》（CITES ）目录，象牙及其制品的市场贸易持续得到国家决策层和学术界的从抵抗制象牙及制品做起，对保护野生动物资源具有重要意义，而在执法过程中如何快速，有效的对类象牙制品进行鉴定与识别，是目前亟待解决的问题。 本项目采用图像增强与纹理解析方法，来辅助解决象牙图像纹理的量化解析问题，通过深度学习算法实现象牙图像鉴定和识别</div>
      <img src="../../assets/pc/description.png" />
    </div>
    <div class="footer">
      <p class="title">导航</p>
      <div class="content">
        <div class="menu">
          <!-- <a @click="goOperation">{{TITLE_RECOGNITION}}</a>
          <span>|</span> -->
          <a href="#sample">{{TITLE_SAMPLES}}</a>
          <span>|</span>
          <a href="#description">{{TITLE_INTRODUCTION}}</a>
        </div>
        <div class="contact">
          <p>南京警察学院</p>
          <p>单位地址：南京仙林大学城文澜路28号</p>
          <p>Powered by <a href="https://timbercld.com" target="_blank">timbercld.com</a></p>
        </div>
        <img src="../../assets/pc/logo.png" />
      </div>
    </div>
    <div class="upload-btn">
      <input class="hide_file" ref ="leftFile" id="upload" type="file" @change="getFile($event)" accept="image/*">
      <img src="../../assets/mobile/camera.png" />
      上传图片 象牙识别
    </div>
    <a-spin class="uploadMask" tip="正在上传..." v-if="uploading"></a-spin>
  </div>
</template>

<script>
// //eslint-disable-next-line import/extensions
import ImgSwiper from './content/ImgSwiper'
import HomeMixin from '../../mixins/home-mixins'
export default {
  name: 'MobileHome',
  mixins: [HomeMixin],
  data() {
    return {
      active: 0
    }
  },
  mounted () {
    this.getExamplesList()
  },
  methods: {
    changeClass(index) {
      this.active = index
    },
    getSlideIndex(e) {
      this.active = e
    },
    goOperation() {
      this.$router.push('/ResultPage')
    }
  },
  components: {
    ImgSwiper
  }
}
</script>

<style lang="less" scoped>
  .main {
    background: #fbf7f0;
    padding-bottom: 16vw;
    .context {
      background: #6C6F5C;
      .text {
        color: #fff;
        box-sizing: border-box;
        padding: 5.07vw 6.4vw;
        .title {
          font-size: 4.8vw;
          margin-bottom:  2.13vw;
          font-weight: bold;
        }
        .content {
          font-size: 2.67vw;
        }
      }
      .banner {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .sample{
      width: 100%;
      padding-top: 9.07vw;
      .title {
        font-size: 3.73vw ;
        font-weight: 600;
        color: #333333;
        margin: 0;
      }
      >hr {
        width: 10.13vw;
        height: 0.53vw;
        border: none;
        background: #333333;
        margin: 1.6vw auto 0;
      }
      .menu {
        margin: 5.33vw 0;
        display: flex;
        align-items: center;
        justify-content: center;
        >div {
          font-size: 3.2vw;
          font-weight: 400;
          color: #333333;
          display: flex;
          align-items: center;
          justify-content: center;
          &.active {
            background-color: #ddbf83;
          }
          &:hover{
            cursor: pointer;
          }
        }
        >div:nth-of-type(1) {
          width: 21.33vw;
          height: 6.4vw;
          border: 2px solid #ddbf83;
          border-radius: 31px 4px 4px 31px;
        }
        >div {
          width: 21.33vw;
          height: 6.4vw;
          border: 2px solid #ddbf83;
          border-radius: 4px;
          margin: 0 2.67vw;
        }
        >div:nth-last-of-type(1) {
          width: 21.33vw;
          height: 6.4vw;
          border: 2px solid #ddbf83;
          border-radius: 4px 31px 31px 4px;
        }
      }
      .display {
        /*/deep/ .slick-dots.slick-dots-bottom.slick-dots.slick-thumb{*/
        /*  display: none !important;*/
        /*}*/
        /deep/ .slick-arrow {
          color: red;
          width: 25px;
          height: 25px;
          font-size: 25px;
          background-color: rgba(31, 45, 61, 0.11);
          opacity: 0.3;
        }
      }
    }
    .description{
      width: 100%;
      padding-top: 9.07vw;
      .title {
        font-size: 3.73vw ;
        font-weight: 600;
        color: #333333;
        margin: 0;
      }
      >hr {
        width: 10.13vw;
        height: 0.53vw;
        border: none;
        background: #333333;
        margin: 1.6vw auto 0;
      }
      .content {
        padding: 5.33vw 7.47vw;
        font-size: 2.67vw;
        color: #333333;
        text-align: left;
      }
      img {
        width: 100%;
      }
    }
    .footer {
      margin-top: 8vw;
      background: #6C6F5C;
      padding: 5.87vw 8vw 7.2vw;
      .title {
        font-size: 3.73vw;
        font-weight: bold;
        color: #fff;
        padding-bottom: 1.6vw;
        width: 10.13vw;
        border-bottom: 0.53vw solid #fff;
        text-align: left;
        white-space: nowrap;
      }
      .content {
        margin-top: 4.33vw;
        text-align: left;
        color: #ffffff;
        .menu {
          font-size: 3.2vw;
          font-weight: 500;
          line-height: 22px;
          >span{
            margin-left: 20px;
            margin-right: 20px;
          }
          >a{
            color: #ffffff;
            cursor: pointer;
          }
        }
        .contact {
          font-weight: 400;
          padding: 4.5vw 0 3.2vw;
          border-bottom: 0.27vw solid #fff;
          font-size: 2.67vw;
          p {
            margin-bottom: .27vw;
            a {
              color: #ddbf83;
            }
          }
        }
      }
      img {
        width: 40.53vw;
        margin-top: 5.67vw;
      }
    }
    .upload-btn {
      height: 16vw;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      background: #DDBF83;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #333;
      font-size: 4.27vw;
      z-index: 1000;
      img {
        width: 5.87vw;
        margin-right: 2.67vw;
      }
      .hide_file {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
      }
    }
    .uploadMask {
      position: fixed !important;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 111111;
      background: rgba(0, 0, 0, .5);
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 0!important;
    }
  }
</style>
